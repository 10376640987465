<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline">
    <mat-label>First Name</mat-label>
    <input type="text" matInput formControlName="firstName" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Last Name</mat-label>
    <input type="text" matInput formControlName="lastName" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input type="email" matInput formControlName="email" />
    <mat-error *ngIf="getFirstError(form.controls.email) as error">{{
      error
    }}</mat-error>
  </mat-form-field>

  <div
    class="password-requirements"
    [style.color]="
      passwordCtrl.invalid && passwordCtrl.touched ? 'red' : 'black'
    "
  >
    <strong>Password requirements</strong>
    <mat-icon
      matTooltipPosition="above"
      matTooltip="Must contain at least 10 characters, at least one lowercase letter, at least one
      uppercase letter, and at least one non-alphanumeric character"
      >info</mat-icon
    >
  </div>

  <mat-form-field appearance="outline" style="margin-bottom: 1.5rem">
    <mat-label>Password</mat-label>
    <input
      [type]="hide ? 'password' : 'text'"
      matInput
      formControlName="newPassword"
    />
    <mat-icon matSuffix color="primary" (click)="hide = !hide">{{
      hide ? "visibility_off" : "visibility"
    }}</mat-icon>
    <mat-error
      *ngIf="getFirstError(form.controls.newPassword) as error"
      style="line-height: 1em"
      >{{ error }}</mat-error
    >
  </mat-form-field>

  <mat-form-field appearance="outline" style="margin-bottom: 1.5rem">
    <mat-label>Confirm Password</mat-label>
    <input
      [type]="hide ? 'password' : 'text'"
      matInput
      formControlName="confirmNewPassword"
    />
    <mat-icon matSuffix color="primary" (click)="hide = !hide">{{
      hide ? "visibility_off" : "visibility"
    }}</mat-icon>

    <mat-error
      *ngIf="getFirstError(form.controls.confirmNewPassword) as error"
      >{{ error }}</mat-error
    >
  </mat-form-field>

  <app-phone-form
    [required]="false"
    [label]="'Phone (optional)'"
    [appearance]="'outline'"
    (formReady)="addChildForm('phone', $event)"
    (valueChange)="setPhone($event)"
  ></app-phone-form>

  <ng-container *ngIf="errorMsg">
    <mat-error style="margin-bottom: 22px">{{ errorMsg }}</mat-error>
  </ng-container>

  <button
    type="submit"
    mat-flat-button
    color="primary"
    [disabled]="!form.valid"
  >
    Register
  </button>
</form>
