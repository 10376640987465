import { Inject, Injectable, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ECOM_EVENT_API_SERVICES } from '@jfw-library/ecommerce/api-services';
import { getEventSteps, getInStoreView } from 'business-logic';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  Event,
} from 'common-types';
import { BehaviorSubject, Subject, Subscription, firstValueFrom } from 'rxjs';
import { IEventService } from './event.service.interface';
import { sizeOf, sizeOfDoc } from 'business-logic';
@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy, IEventService {
  public selectedEvent: Event | null = null;
  private subscription = new Subscription();
  private dealerPortal = this.environment.dealerPortal;

  public selectedEvent$ = new BehaviorSubject<Event>({} as Event);
  public eventSaveError$ = new BehaviorSubject<boolean>(false);
  public transferEventError$ = new BehaviorSubject<boolean>(false);
  public canProceed$ = new Subject<boolean>();
  public nextClicked$ = new Subject<boolean>();
  public forceNextStep$ = new Subject<void>();
  public validateStep$ = new Subject<void>();

  // Stuff for assign looks temporarily
  public nextStepEventClick$ = new BehaviorSubject<string | undefined>('');
  //

  private ecomEventApiService = this.environment?.dealerPortal
    ? inject(ECOM_EVENT_API_SERVICES.v6)
    : inject(ECOM_EVENT_API_SERVICES.v6);

  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    private router: Router
  ) {
    console.log(
      'EventService is using Event API version: ',
      this.ecomEventApiService.apiUrl
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /*
   ***********************************************************************
   *                               EVENT STORE                           *
   ***********************************************************************
   */

  public async setSelectedEventWithEvent(event: Event): Promise<Event> {
    console.log('setSelectedEventWithEvent event: ', event);
    this.selectedEvent = event;
    this.selectedEvent$.next(event);
    localStorage.setItem('eventId', event.id);
    localStorage.setItem('selectedEvent', JSON.stringify(event));
    return new Promise((resolve, reject) => {
      resolve(event);
    });
  }
  public async setSelectedEvent(eventId: string): Promise<Event> {
    console.log('setSelectedEvent eventId: ', eventId);
    try {
      const event = await this.getEventById(eventId);

      let eventObjSize = sizeOf(event);
      let eventDocSize = sizeOfDoc(event);
      console.log('eventObjSize: ', eventObjSize);
      console.log('eventDocSize: ', eventDocSize);
      this.selectedEvent = event;
      this.selectedEvent$.next(event);
      localStorage.setItem('eventId', eventId);
      localStorage.setItem('selectedEvent', JSON.stringify(event));
      return event;
    } catch (error) {
      console.error('Error setting selected event:', error);
      throw error;
    }
  }

  public async getSelectedEvent(forceUpdate: boolean = false): Promise<Event> {
    console.log('getSelectedEvent called');
    if (!forceUpdate && this.selectedEvent !== null) {
      console.log('getSelectedEvent is returning this.selectedEvent');
      return this.selectedEvent;
    } else {
      const eventId = localStorage.getItem('eventId');
      if (eventId === null) {
        throw new Error('No eventId in getSelectedEvent');
      }

      try {
        console.log('getSelectedEvent is fetching event from api');
        const event = await this.getEventById(eventId);
        this.selectedEvent = event;
        this.selectedEvent$.next(event);
        return event;
      } catch (error) {
        console.error('Error in getSelectedEvent:', error);
        throw error;
      }
    }
  }

  private async getEventById(id: string): Promise<Event> {
    return (await firstValueFrom(this.ecomEventApiService.getEventById(id)))
      .event;
  }

  public routeToFirstStep() {
    if (this.selectedEvent) {
      let route = getEventSteps(this.selectedEvent, this.dealerPortal)[0].route;
      if (getInStoreView(this.selectedEvent)) {
        // check if the in store event has already been submitted
        if (
          this.selectedEvent.inStoreInfo !== undefined &&
          this.selectedEvent.inStoreInfo.dateSharedWithStore !== undefined
        ) {
          route = 'confirmation';
        }
      }
      return this.router.navigate(['/event', this.selectedEvent.id, route]);
    }
    return;
  }

  public routeToLastStep() {
    if (this.selectedEvent) {
      // 2 is subtracted from array length - last element is first step
      // of checkout, second to last element is final step of event
      const steps = getEventSteps(this.selectedEvent, this.dealerPortal);
      const route = getEventSteps(this.selectedEvent, this.dealerPortal)[
        steps.length - 2
      ].route;
      return this.router.navigate(['/event', this.selectedEvent.id, route]);
    }
    return;
  }

  /*
   ***********************************************************************
   *                              UPDATE EVENT                           *
   ***********************************************************************
   */

  /** Updates the event in the database with the provided event object.  The lastUpdated field is updated to the current time.  The styleCodes field is also rebuilt.
   * When finished, sets the selectedEvent on the EventService to the updated event */
  // public async updateEvent(event: Event, simulateDelay: number = 0): Promise<Event> {
  //   console.log("updateEvent called");
  //   const data: Event = {
  //     ...event,
  //     lastUpdated: dayjs(Date.now()).valueOf().toString(),
  //     styleCodes: this.rebuildStyleCodeList(event),
  //   };

  //   // if (typeof arguments.callee === 'function') {
  //   //   console.log('innerFunction was called by:', arguments.callee.name);
  //   // } else {
  //   //   console.log('innerFunction was called directly');
  //   // }

  //   return firstValueFrom(
  //     this.ecomEventApiService.saveEvent(data).pipe(
  //       switchMap((savedEvent) =>
  //         timer(simulateDelay).pipe(map(() => savedEvent))
  //       ),
  //       tap((savedEvent) => {
  //         let genericEventData = JSON.parse(JSON.stringify(savedEvent));
  //         if (
  //           genericEventData.errorOnUpdate !== undefined &&
  //           genericEventData.errorOnUpdate
  //         ) {
  //           console.log('SHOULD BE OPENING ERROR MODAL');
  //           this.eventSaveError$.next(true);
  //         } else {
  //           this.eventSaveError$.next(false);
  //         }
  //         this.selectedEvent = savedEvent;
  //         this.selectedEvent$.next(savedEvent);

  //         localStorage.setItem('selectedEvent', JSON.stringify(savedEvent));
  //         //return savedEvent;
  //       }),
  //       catchError((err) => {
  //         console.error('Error saving event in updateEvent:', err);
  //         throw err;
  //       })
  //     )
  //   );

  // } // end updateEvent

  // private rebuildStyleCodeList(event: Event): string[] {
  //   let styleCodes: string[] = [];

  //   console.log('rebuilding styleCode list');

  //   event.looks.forEach((look) => {
  //     look.styles.forEach((style) => {
  //       if (!styleCodes.includes(style.styleCode)) {
  //         styleCodes.push(style.styleCode);
  //       }
  //     });
  //   });

  //   event.members.forEach((member) => {
  //     member.memberLook?.styles.forEach((style) => {
  //       if (!styleCodes.includes(style.styleCode)) {
  //         styleCodes.push(style.styleCode);
  //       }
  //     });
  //   });

  //   return styleCodes;
  // }

  // public deleteAdminFromMember(member: EventMember, event: Event): void {
  //   const adminIndex = event.admins.findIndex(
  //     (admin) => admin.id === member.userId
  //   );

  //   if (adminIndex !== -1) {
  //     event.admins.splice(adminIndex, 1);
  //   }
  // }

  // public async updateEventMembers(
  //   member: EventMember,
  //   memberIndex: number,
  //   memberIsCurrentUser: boolean
  // ): Promise<Event> {
  //   if (this.selectedEvent) {
  //     if (memberIndex === -1) {
  //       // member does not exist yet

  //       if (memberIsCurrentUser) {
  //         // add or update admin

  //         member.userId = this.user?.uid!;
  //         // EXT-1280 removed for now
  //         // this.createAdminFromMember(member, this.selectedEvent);
  //       }
  //       if (member.id) {
  //         this.selectedEvent.memberIds?.push(member.id);
  //       }
  //       this.selectedEvent.members.push(member);
  //     } else {
  //       // member already exists

  //       if (memberIsCurrentUser) {
  //         // add or update admin

  //         member.userId = this.user?.uid!;
  //         // EXT-1280 removed for now
  //         // this.createAdminFromMember(member, this.selectedEvent);
  //       } else {
  //         // delete corresponding admin if necessary

  //         this.deleteAdminFromMember(member, this.selectedEvent);
  //         member.userId = undefined;
  //       }
  //       this.selectedEvent.members[memberIndex] = member;
  //     }

  //     return this.updateEvent(this.selectedEvent);
  //   }

  //   return Promise.reject('No event selected');
  // }

  /*
   ***********************************************************************
   *                              CREATE EVENT                           *
   ***********************************************************************
   */

  // public createEventPromise(event: Event): Promise<Event> {
  //   if (this.user === null) {
  //     throw new Error('User is null.  Unable to create event');
  //   }

  //   const events: Event[] = [];
  //   event.id = '1';
  //   event.createdByUserId = this.user?.uid;
  //   event.memberIds = [];
  //   if (event.isSingleUser) {
  //     let sMember = event.members[0];
  //     if (this.user?.uid && this.user?.displayName) {
  //       sMember.firstName = this.user?.displayName;
  //       sMember.lastName = ' ';
  //       sMember.userId = this.user?.uid;
  //       sMember.userPaying = this.user?.uid;
  //       if (sMember.id) {
  //         event.memberIds.push(sMember.id);
  //       }
  //     }
  //   }

  //   let dealerProcessing = false;
  //   if (this.environment.dealerPortal) {
  //     dealerProcessing = true;
  //   }

  //   const requestBody = {
  //     event,
  //     dealerProcessing,
  //     // organizer,
  //     user: this.user,
  //   };

  //   return firstValueFrom(this.ecomEventApiService.createEvent(requestBody).pipe(
  //     tap((event) => {
  //       if (event) {
  //         event.id = event.id;
  //         event.lastUpdated = event.lastUpdated;
  //         events.push(event);
  //         this.selectedEvent = event;
  //         this.selectedEvent$.next(event);
  //         localStorage.setItem('eventId', event.id);
  //         localStorage.removeItem('checkout_state');
  //         // resolve(event);
  //       }
  //     })
  //   ));

  // } // end createEventPromise
}
