import { Component, Inject } from '@angular/core';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  Persistence,
} from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  SignInFormControls,
  ZodFormUtilities,
} from '@jfw-library/ecommerce/zod-forms';
import {
  AfterSignIn,
  AuthErrorCode,
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  SignInFormSchema,
  Site,
} from 'common-types';
import { AuthV2Service } from '../../services/auth/authV2/authV2.service';
import { CreateAccountModalComponent } from '../create-account-modal/create-account-modal.component';
import {
  ForgotPasswordDialogData,
  ForgotPasswordModalComponent,
} from '../forgot-password-modal/forgot-password-modal.component';

// Don't think the last three are necessary, but leaving them in for now
const SignInErrorMessages = {
  // [AuthErrorCode.auth_user_not_found]: 'Account does not exist',
  // [AuthErrorCode.auth_wrong_password]: 'Invalid email/password combination',
  [AuthErrorCode.auth_invalid_login_credentials]:
    'Invalid email/password combination',
  [AuthErrorCode.auth_unverified_email]:
    'Your email address has not been verified. Please check your email for a link we sent to you.',
  'email-not-verified':
    'Your email address has not been verified. Please check your email for a link we sent to you.',
  // EMAIL_NOT_FOUND: 'Account does not exist',
  // INVALID_PASSWORD: 'Invalid Password',
  // USER_DISABLED: 'Account is disabled',
} as const;

type SignInErrorMessages = keyof typeof SignInErrorMessages;

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss'],
})
export class SignInModalComponent {
  rememberMe: boolean = false;
  errorCode: string = '';
  errorMessage: string = '';
  hide: boolean = true;
  showSpinner: boolean = false;

  signInForm = new FormGroup<SignInFormControls>(
    {
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    [ZodFormUtilities.zodFormGroupValidator(SignInFormSchema)]
  );

  /**
   * Used to display the first zod schema field error message in the form.
   *
   * getFirstSchemaFieldErrorMessage is used instead of getFirstOfAllFieldErrorsZodFirst because
   * the auth_user_not_found error (after email/password submit) needs to just highlight the email field, not display an error message on the field
   */
  getFirstError = ZodFormUtilities.getFirstSchemaFieldErrorMessage;

  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    @Inject(MAT_DIALOG_DATA) private data: { afterSignIn?: AfterSignIn } | null,
    private authV2Service: AuthV2Service,
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SignInModalComponent>
  ) {}

  hasProdMode(): boolean {
    return this.environment.prod_mode_allow_normal_access;
  }

  onSignIn() {
    console.log('onSignIn submit');
    console.log(this.router.routerState);

    const { email, password } = this.signInForm.value;
    if (this.signInForm.valid && email && password) {
      this.errorCode = '';
      this.errorMessage = '';
      this.showSpinner = true;

      let persistence: Persistence = browserSessionPersistence;
      if (this.rememberMe) {
        persistence = browserLocalPersistence;
      }

      try {
        this.authV2Service
          .signIn(
            Site.Ecom,
            email,
            password,
            undefined,
            persistence,
            undefined,
            true,
            undefined,
            this.data?.afterSignIn
          )
          .then((result) => {
            console.log('signIn response: ', result);

            // if result has a value, we have an error
            if (result) {
              // if a corresponding error message exists, use it, otherwise use the result
              const message =
                SignInErrorMessages[result as SignInErrorMessages] ??
                'Sign-In Error';
              console.log('error message to display:', message);
              this.errorMessage = message;
              this.errorCode = result;
              console.log('errorCode:' + this.errorCode);

              this.signInForm.controls.password.reset();

              // put error on email field if user not found
              if (result === AuthErrorCode.auth_user_not_found) {
                console.log('setting error on email field...');
                setTimeout(() => {
                  this.signInForm.controls.email.setErrors({
                    auth_user_not_found: true,
                  });
                }, 100);
              }

              this.showSpinner = false;
            } else {
              this.showSpinner = false;
              this.dialogRef.close({ success: true });
              // if (this.data !== undefined && this.data !== null) {
              //   window.location.reload();
              // }
            }
          });
      } catch (error: any) {
        this.showSpinner = false;
        console.log(error);
      }
    }
  }

  onCreateAccount(): void {
    this.dialogRef.close({ success: false });
    this.dialog.open(CreateAccountModalComponent, {
      data: { afterSignIn: this.data?.afterSignIn },
      autoFocus: false,
      maxWidth: '380px',
    });
  }

  onGuestLogout(): void {
    if (
      confirm(
        'This will take you back to the sign-in page. Are you sure you want to do that?'
      )
    ) {
      this.dialogRef.close({ success: false });
      this.router.navigate(['/V2login']);
    }
  }

  chkRememberMe(event: MatCheckboxChange): void {
    this.rememberMe = event.checked;
    // console.log('remember me?:' + this.rememberMe);
  }

  onForgotPassword(): void {
    const data: ForgotPasswordDialogData = {
      site: this.environment.site,
    };
    this.dialogRef.close({ success: false });
    this.dialog.open(ForgotPasswordModalComponent, {
      data,
      autoFocus: false,
      maxWidth: '340px',
    });
  }
}
