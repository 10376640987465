import { isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import {
  AnonTokenRequestData,
  AnonTokenResponse,
  ValidateTokenRequestData,
  ValidateTokenResponse,
  VerifyProdModeRequestData,
  VerifyProdModeResponse,
} from 'common-types';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { firstValueFrom } from 'rxjs';

/**
 * This service is used to fetch and validate anonymous JWT tokens.
 * When initAnonToken is called, first we get a token from the token validation api.
 * This token has an expiration date that is set according to the value of environment.token_expire_minutes.  This is the TTL of the token.
 * The TokenInterceptor will intercept all http requests and attempt to add a Bearer token to the request's Authorization header for logged in users or set the AnonJwtToken and AnonSessionUserId headers for non-logged-in users.
 *
 * token_prod cookie stores the JWT from the validation api.
 * token_prod_session_userid cookie stores the userId.
 * token_prod_validated cookie is set to 'ready' when the token has been validated.
 *
 *
 * three cookies are set:
 * 1. token_prod_validated is set to 'ready'.  This cookie is used to determine if the token has been validated.
 * If this cookie exists, then the token has been validated.  This cookie is deleted when clearAnonTokenCookies is called.
 * The cookie needs to be re-checked every time the page is loaded, because the cookie is set to expire in 60 minutes.
 */
@Injectable({
  providedIn: 'root',
})
export class AnonAuthService {
  private TOKEN_VALIDATION_REST_API_SERVER =
    this.environment.token_validation_api_server_url;

  // user: firebase.User | null = null;
  user: User | null = null;

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
    private cookieService: SsrCookieService
  ) {}

  /**
   * Sets three cookies:
   * 1. token_prod_validated - represents whether the token has been validated.  This cookie is set to 'ready' when the token has been validated.
   * 2. token_prod_session_userid - userId of the current session (1st part of the AnonToken)
   * 3. token_prod - JWT token from the token validation api (2nd part of the AnonToken)
   *
   * @param sessionUserId the userId of the current session
   * @returns true if the token was successfully fetched and the cookies were set.  Otherwise, returns false.
   */
  public async initAnonToken(sessionUserId: string): Promise<boolean> {
    /// Get/Set minutes that token_prod and token_prod_session_userid cookies are valid.  These cookies make up the AnonToken.
    const expireMinutes: number = this.environment.token_expire_minutes;

    const tokenAndUserIdCookiesExpireDate = new Date();

    // token_prod_session_userid and token_prod cookies expire in expireMinutes minutes from now
    tokenAndUserIdCookiesExpireDate.setMinutes(
      tokenAndUserIdCookiesExpireDate.getMinutes() + expireMinutes
    );

    /// Get/Set number of minutes that will pass until another token validity check occurs
    const minutesUntilTokenValidation: number =
      this.environment.token_validation_expiration_minutes;

    const validationExpirationDate = new Date();

    // token_prod_validated cookie expires in minutesUntilTokenValidation minutes from now (currently 1 minute)
    validationExpirationDate.setMinutes(
      validationExpirationDate.getMinutes() + minutesUntilTokenValidation
    );

    /// JWT token expire date.  One minute longer than token_prod and token_prod_session_userid cookies.
    const jwtTokenExpireMinutes: number = expireMinutes + 1;

    /// Get/Refresh new Anon JWT Token, which will expire in jwtTokenExpireMinutes minutes
    const retToken = await this.getToken(sessionUserId, jwtTokenExpireMinutes);

    if (
      retToken !== undefined && //
      retToken && // api returned a token
      retToken !== 'invalid' && // api returned 'invalid'
      retToken !== 'TOKEN_PROD_VALIDATED' // token has already been validated, a new token was not fetched
    ) {
      console.log('%c getToken success (AnonToken)', 'background-color:green;color:white');
      // console.log("token: ", retToken);
      this.cookieService.set(
        'token_prod_validated', // represents whether the token has been validated
        'ready',
        validationExpirationDate,
        '/',
        undefined,
        false,
        undefined
      );

      this.cookieService.set(
        'token_prod_session_userid', // userId of the current session
        sessionUserId,
        tokenAndUserIdCookiesExpireDate,
        '/',
        undefined,
        false,
        undefined
      );

      this.cookieService.set(
        'token_prod', // JWT token from the token validation api
        retToken,
        tokenAndUserIdCookiesExpireDate,
        '/',
        undefined,
        false,
        undefined
      );

      return true; // SUCCESS: values for AnonToken were stored in cookies and token_prod_validated cookie was set to 'ready'
    } else {
      /// If token_prod_validation cookie already exists,
      /// and we're in "Prod Normal" mode, then let it go.
      if (
        retToken === 'TOKEN_PROD_VALIDATED' &&
        this.environment.prod_mode_authorization_rules &&
        this.environment.prod_mode_allow_normal_access
      ) {
        console.log('%c getToken returned TOKEN_PROD_VALIDATED', 'background-color:red;color:white');
        return true; // SUCCESS: token_prod_validated cookie already exists and we're in "Prod Normal" mode
      }

      console.log('%c getToken failed', 'background-color:red;color:white');
      this.clearAnonTokenCookies();
      return false; // FAILURE: All cookies were cleared.
    }

    // this.getToken(sessionUserId, jwtTokenExpireMinutes).then((res: any) => {
    //   if (res && res !== 'invalid') {
    //     this.cookieService.set(
    //       'token_prod',
    //       res,
    //       tokenExpireDate,
    //       '/',
    //       undefined,
    //       false,
    //       undefined
    //     );
    //   }
    // });
  }

  /**
   * If token_prod_validated cookie exists, then return a promise of 'TOKEN_PROD_VALIDATED' and does NOT fetch a new token.
   * If token_prod_validated cookie does not exist:
   *
   *    1. Get refreshKey:
   *        - If prod_mode_authorization_rules is true:
   *            - If prod_mode_allow_normal_access is false, then get refreshKey from 'token_refresh_key' cookie.
   *            - If prod_mode_allow_normal_access is true, then get refreshKey from environment.prod_mode_refresh_key.
   *         - If prod_mode_authorization_rules is false, then refreshKey = '', which will cause getToken to return undefined.
   *    2. If refreshKey is blank, something went wrong, so clearAnonTokenCookies and return undefined.
   *    3. If refreshKey is not blank, then call {@link apiGetToken} with refreshKey, userId, and expiresInMinutes.
   *
   * @param userId the userId to use when fetching a new token
   * @param expiresInMinutes the number of minutes the token will be valid
   * @returns a promise of one of these four results:
   * 1. undefined if refreshKey or userId is blank, or if the token validation api errors.
   * 2. 'TOKEN_PROD_VALIDATED' if the token_prod_validated cookie already exists.
   * 3. 'invalid' if the token validation api returns 'invalid'.
   * 4. a JWT string if the api successfully validates the request (the refreshKey matches the prodModeKey) and returns a JWT string.
   */
  public getToken(
    userId: string,
    expiresInMinutes: number
  ): Promise<AnonTokenResponse | undefined> {
    // console.log('getToken?');

    ///if (password === '' && this.environment.prod_mode_refresh_key)

    try {
      /// Let's see if token_prod_validated is set, and if so, don't getToken
      if (this.cookieService.check('token_prod_validated')) {
        console.log(
          '%c!! getToken: token_prod_validated cookie exists > do not fetch new token',
          'background-color:red;color:white'
        );
        return Promise.resolve('TOKEN_PROD_VALIDATED');
      }

      let refreshKey = '';

      if (this.environment.prod_mode_authorization_rules) {
        if (!this.environment.prod_mode_allow_normal_access) {
          // the 'token_refresh_key' cookie should have been set by the user entering the prod mode password on the prod-login.component
          refreshKey = this.cookieService.get('token_refresh_key');
        } else {
          refreshKey = this.environment.prod_mode_refresh_key;
        }
      }

      /// If refreshKey or userId is blank, something went wrong.
      if (refreshKey === '' || userId === '') {
        console.log(
          '%cError RefreshKey or UserId is empty',
          'background-color:red;color:white'
        );
        this.clearAnonTokenCookies();
        return Promise.resolve(undefined);
      }

      const requestData: AnonTokenRequestData = {
        refreshKey: refreshKey,
        userId: userId,
        expiresInMinutes,
        site: this.environment.site,
      };

      const getApiToken = this.apiGetToken(requestData);

      return getApiToken;
    } catch (error: any) {
      console.log('getToken error');
      return Promise.resolve(undefined);
    }
  }

  /**
   * Returns a new token from the token validation api.
   * @param requestData
   * @returns
   */
  private async apiGetToken(
    requestData: AnonTokenRequestData
  ): Promise<AnonTokenResponse | undefined> {
    // console.log('apiGetToken?');
    const apiUrl = `${this.TOKEN_VALIDATION_REST_API_SERVER}/getToken`;

    // console.log(apiUrl);

    return firstValueFrom(
      this.httpClient.post<string>(apiUrl, requestData, {
        // ...requireAppCheck,
        headers: this.reqHeader,
      })
    ).catch((error: any) => {
      console.log('Got an error in apiGetToken');
      console.log(error);
      return undefined;
    });
  }

  /**
   * Sets the token_prod_last_validation_attempt cookie's value to the provided attemptDate (should be Date.now())
   * and sets the cookie's expiration date to 60 minutes from now.
   * @param attemptDate The date of the last token validation attempt.  This should be passed in as Date.now().
   */
  private setTokenLastValidationAttempt(attemptDate: number) {
    const lastValidationAttemptCookieExpireDate = new Date();

    // Set the cookie to expire in 60 minutes
    lastValidationAttemptCookieExpireDate.setMinutes(
      lastValidationAttemptCookieExpireDate.getMinutes() + 60
    );

    this.cookieService.set(
      'token_prod_last_validation_attempt', // used to throttle token validation attempts
      attemptDate.toString(),
      lastValidationAttemptCookieExpireDate,
      '/',
      undefined,
      false,
      undefined
    );
  }

  /**
   * Checks to see if token_prod_last_validation_attempt cookie exists.
   *    - If it does, then checks the difference between validationTokenDateCurrent and the value of token_prod_last_validation_attempt cookie.  Returns true if the difference between validationTokenDateCurrent and the value of token_prod_last_validation_attempt cookie is less than the value of environment.token_validation_min_allowed_ms_between_attempts.
   *    - If no cookie exists, then returns false.
   * @param validationTokenDateCurrent The current date of the token validation attempt.  This should be passed in as Date.now().
   * @returns
   */
  private isValidationAttemptTooSoon(
    validationTokenDateCurrent: number
  ): boolean {
    let validationAttemptTooSoon: boolean = false;

    console.log('validationTokenDate:', validationTokenDateCurrent);

    if (this.cookieService.check('token_prod_last_validation_attempt')) {
      console.log('Checking Last Validation Attempt');
      let lastValidationAttempt: number = parseInt(
        this.cookieService.get('token_prod_last_validation_attempt')
      );
      console.log('Last Validation Attempt was:', lastValidationAttempt);

      let timeSinceLastValidationAttempt: number =
        validationTokenDateCurrent - lastValidationAttempt;

      console.log(
        'Elapsed Milliseconds since Last Validation Attempt was:',
        timeSinceLastValidationAttempt
      );

      if (
        timeSinceLastValidationAttempt <
        this.environment.token_validation_min_allowed_ms_between_attempts
      ) {
        validationAttemptTooSoon = true;
      }
    }

    return validationAttemptTooSoon;
  }

  /**
   * Used by the  [AnonTokenGuard](../../guards/anon/anon-token.guard.ts) to check if the token has been validated.
   * First checks to see if the validation attempt is too soon:
   *    - if too soon, returns undefined
   *    - if not too soon, calls the token validation api to validate the provided token and userId
   * and sets the token_prod_last_validation_attempt cookie's value to the current date.
   *
   * @param userId userId of the current session.  This is what should match the userId in the token.
   * @param token The JWT token to validate.  This is what should match the userId in the token.
   * @returns undefined if the validation attempt is too soon, if the userId or token is an empty string, or if the validation api errors.
   * Otherwise, returns the result of the token validation api call as a promise of a {@link ValidateTokenResponse} object.
   */
  public async validateToken(
    userId: string,
    token: string
  ): Promise<ValidateTokenResponse | undefined> {
    console.log(
      '%cValidating JWT Token',
      'background-color:orange;color:white'
    );

    let validationAttemptDate = Date.now();

    try {
      const validationAttemptTooSoon = this.isValidationAttemptTooSoon(
        validationAttemptDate
      );

      if (validationAttemptTooSoon) {
        // throttle token validation attempts
        console.log(
          '%cValidation Attempt is Too Soon',
          'background-color:red;color:white'
        );
        return await Promise.resolve(undefined);
      }

      if (userId === '' || token === '') {
        // one or both of the cookies was not set (token_prod_session_userid or token_prod)
        console.log(
          '%cvalidateToken: UserId or Token is blank.',
          'background-color:red;color:white'
        );
        this.clearAnonTokenCookies(true);
        return await Promise.resolve(undefined);
      }

      this.setTokenLastValidationAttempt(validationAttemptDate);

      const requestData: any = {
        userId: userId,
        token: token,
      };

      return await this.apiValidateToken(requestData);
    } catch (error: any) {
      console.log(
        '%cError Validating Token',
        'background-color:red;color:white'
      );
      this.clearAnonTokenCookies(true);

      this.setTokenLastValidationAttempt(validationAttemptDate);

      return Promise.resolve(undefined);
    }
  }

  /**
   * Calls the token validation api to validate the provided token and userId.
   * @param requestData A {@link ValidateTokenRequestData} object with userId and token properties.
   * userId is the userId of the current session.
   * token is the JWT token to validate.
   * @returns a promise of undefined if the token validation api errors.  Otherwise, returns a promise of a {@link ValidateTokenResponse} object.
   */
  private async apiValidateToken(
    requestData: ValidateTokenRequestData
  ): Promise<ValidateTokenResponse | undefined> {
    //console.log('apiValidateToken?');
    const apiUrl = `${this.TOKEN_VALIDATION_REST_API_SERVER}/validateToken`;

    //console.log(apiUrl);

    try {
      return await firstValueFrom(
        this.httpClient.post<ValidateTokenResponse>(apiUrl, requestData, {
          // ...requireAppCheck,
          headers: this.reqHeader,
        })
      );
    } catch (error) {
      // Handle the error here
      console.error('Error occurred during token validation');
      return Promise.resolve(undefined);
    }
  }

  /**
   * Calls {@link apiVerifyProdMode} which calls the token validation api to verify that
   * the provided password matches the prodModeKey that is set on the backend.
   * @param password
   * @returns a promise of a {@link VerifyProdModeResponse} object.
   */
  public async verifyProdMode(
    password: string
  ): Promise<VerifyProdModeResponse> {
    console.log(
      '%cVERIFYING PROD MODE RESTRICTED ENTRY',
      'background-color:orange;color:white'
    );

    const requestData: VerifyProdModeRequestData = {
      password: password,
    };

    return await this.apiVerifyProdMode(requestData);
  }

  /**
   * Calls the token validation api to verify that the provided password matches the prodModeKey.
   * @param requestData A {@link VerifyProdModeRequestData} object with password property.
   * @returns
   */
  private async apiVerifyProdMode(
    requestData: VerifyProdModeRequestData
  ): Promise<VerifyProdModeResponse> {
    //console.log('apiValidateToken?');
    const apiUrl = `${this.TOKEN_VALIDATION_REST_API_SERVER}/verifyProdMode`;

    //console.log(apiUrl);

    try {
      return await firstValueFrom(
        this.httpClient.post<VerifyProdModeResponse>(apiUrl, requestData, {
          // ...requireAppCheck,
          headers: this.reqHeader,
        })
      );
    } catch (error) {
      // Handle the error here
      console.error('Error occurred during prodMode validation:', error);
      throw error; // Rethrow the error to propagate it to the caller
    }
  }

  /**
   * Removes four cookies:
   * 1. token_prod_validated is set to 'ready', and then deleted.
   * 2. If deleteSessionUserIdCookie is true, token_prod_session_userid is set to ' ', and then deleted.
   * 3. token_prod is set to ' ', and then deleted.
   * 4. token_refresh_key is set to ' ', and then deleted.
   * Cookies are set with new expired dates (in the past), and then deleted. This ensures that the cookies are deleted in all browsers and environments.
   * @param deleteSessionUserIdCookie When true, the token_prod_session_userid cookie will be deleted.
   */
  public clearAnonTokenCookies(deleteSessionUserIdCookie: boolean = false) {
    const tokenCookieExpireDate = new Date();
    tokenCookieExpireDate.setMinutes(tokenCookieExpireDate.getMinutes() - 1000);

    // if (!this.isBrowser) return;
    console.log('Clearing Prod Anon Cookies');

    /// It looks like it might be more effective when deleting these cookies
    /// to actually set them with expired dates, which appears to then delete the cookies
    setTimeout(() => {
      this.cookieService.set(
        'token_prod_validated',
        'ready',
        tokenCookieExpireDate,
        '/',
        undefined,
        false,
        undefined
      );

      if (deleteSessionUserIdCookie) {
        this.cookieService.set(
          'token_prod_session_userid',
          '',
          tokenCookieExpireDate,
          '/',
          undefined,
          false,
          undefined
        );
      }

      this.cookieService.set(
        'token_prod',
        '',
        tokenCookieExpireDate,
        '/',
        undefined,
        false,
        undefined
      );

      this.cookieService.set(
        'token_refresh_key',
        '',
        tokenCookieExpireDate,
        '/',
        undefined,
        false,
        undefined
      );

      if (deleteSessionUserIdCookie) {
        this.cookieService.delete('token_prod_session_userid');
      }

      this.cookieService.delete('token_prod');
      this.cookieService.delete('token_prod_validated');
      this.cookieService.delete('token_refresh_key');
    });
  }
}
