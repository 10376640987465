import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '@jfw-library/platform/services';
import { AccountMaster } from 'common-types';
import { first } from 'rxjs';

@Component({
  selector: 'jfw-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  showSpinner = true;
  accountNumber: string | undefined;
  account: AccountMaster | undefined;

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.accountNumber = this.route.snapshot.params.accountNumber;

    if (this.accountNumber) {
      this.accountService
        .apiGetAccountByAccountNumber(this.accountNumber)
        .pipe(first())
        .subscribe({
          next: (data) => {
            this.account = data;
            this.showSpinner = false;
            if (!this.account) {
              alert(
                `No account exists with account number ${this.accountNumber}.`
              );
              this.router.navigate(['/account/manage']);
            }
            console.log(data);
          },
        });
    } else {
      this.router.navigate(['/account/manage']);
    }
  }
}
