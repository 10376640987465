<div class="modal-main">
  <div class="close-modal">
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-header-container">
    <h3>Verify Your Email</h3>
  </div>
  <mat-dialog-content>
    @if (showForm) {
      <p>Please enter the six-digit code that was emailed to you.</p>
      <mat-form-field>
        <input
          matInput
          type="text"
          [(ngModel)]="code"
          (ngModelChange)="onChange()"
          numbersOnly
          maxlength="6"
          pattern="\d*"
        />
        @if (showSpinner) {
          <mat-spinner diameter="30"></mat-spinner>
        }
      </mat-form-field>

      <a role="button" (click)="onResend()">Resend Code</a>
    }

    @if ({ value: verifyEmailResponse$ | async }; as context) {
      @if (context.value === true) {
        <p>Success!</p>
      }
      @if (context.value === false) {
        <mat-error>Invalid code.</mat-error>
      }
    }
    @if (sendVerificationCodeResponse$ | async) {
      <p>The verification code was resent.</p>
    }
  </mat-dialog-content>
</div>
