<mat-accordion multi="true">
  <mat-nav-list>
    <a mat-list-item routerLink="/home">Home</a>
    <ng-container *ngFor="let featureModuleGroup of featureModuleGroups">
      <mat-expansion-panel mat-list-item class="mat-elevation-z0">
        <mat-expansion-panel-header>
          {{ featureModuleGroup.title }}
        </mat-expansion-panel-header>
        <mat-nav-list>
          <ng-container
            *ngFor="let featureModule of featureModuleGroup.featureModules"
          >
            <a
              mat-list-item
              [routerLink]="featureModule.route"
              *ngIf="hasPermissionToRoute(featureModule.route)"
              >{{ featureModule.title }}</a
            >

            <a
              mat-list-item
              style="opacity: 0.3; cursor: default"
              *ngIf="!hasPermissionToRoute(featureModule.route)"
              >{{ featureModule.title }}</a
            >
          </ng-container>
        </mat-nav-list>
      </mat-expansion-panel>
    </ng-container>
  </mat-nav-list>
</mat-accordion>
