<div class="favorites" *ngIf="portalUser">
  <ng-container *ngFor="let featureModuleGroup of featureModuleGroups">
    <ng-container
      *ngFor="let featureModule of featureModuleGroup.featureModules"
    >
      <mat-card
        *ngIf="hasPermissionToRoute(featureModule.route)"
        appearance="outlined"
        [routerLink]="featureModule.route"
      >
        <mat-card-title>{{ featureModule.title }}</mat-card-title>
        <mat-card-subtitle>
          {{ featureModule.description }}
        </mat-card-subtitle>
      </mat-card>

      <mat-card
        *ngIf="!hasPermissionToRoute(featureModule.route)"
        appearance="outlined"
        style="opacity: 0.3; cursor: default"
      >
        <mat-card-title>{{ featureModule.title }}</mat-card-title>
        <mat-card-subtitle>
          {{ featureModule.description }}
        </mat-card-subtitle>
      </mat-card>
    </ng-container>
  </ng-container>
</div>
