<div class="modal-main">
  <div class="close-modal">
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="modal-header-container">
    <h3>Password Reset</h3>
  </div>

  <div class="forgot-password-modal-main">
    <mat-dialog-content>
      <div *ngIf="passwordReset">
        <p style="margin-top: 0px">
          <strong>Thank you.</strong>
        </p>

        <div style="width: 100%; text-align: left">
          If the email address you provided is associated with an existing
          account, then an email was sent to you with further instructions for
          resetting your password.
        </div>
      </div>

      <div *ngIf="passwordResetError">
        <p style="margin-top: 0px">
          <strong>Uh Oh.</strong>
        </p>
        <p style="text-align: center">
          There was an error sending the password reset email. Please try again
          or contact customer service.
        </p>
      </div>

      <div *ngIf="!passwordReset && !passwordResetError">
        <strong>Forgot your password?</strong>

        <p>We'll email you a link to reset your password.</p>

        <form [formGroup]="signInForm" (ngSubmit)="onPasswordReset()">
          <mat-form-field
            appearance="outline"
            [ngClass]="
              signInForm.get('email')?.invalid &&
              signInForm.get('email')?.touched
                ? 'error'
                : ''
            "
          >
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" />
            <mat-error>
              <span *ngIf="signInForm.get('email')?.errors?.required">
                Email is required.
              </span>
              <span *ngIf="signInForm.get('email')?.errors?.email">
                Email is not valid.
              </span>
            </mat-error>
          </mat-form-field>

          <button
            mat-flat-button
            color="primary"
            type="submit"
            [disabled]="!signInForm.valid || resettingPassword"
            style="width: 250px"
          >
            <ng-container *ngIf="!resettingPassword; else spinner">
              RESET PASSWORD
            </ng-container>

            <ng-template #spinner>
              <mat-progress-spinner
                color="primary"
                value="100"
                mode="indeterminate"
                style="margin-left: auto; margin-right: auto"
                diameter="20"
                strokeWidth="2"
              >
              </mat-progress-spinner>
            </ng-template>
          </button>

          <p style="margin-top: 5px; margin-bottom: 5px">- or -</p>

          <button
            mat-flat-button
            color="accent"
            (click)="onCancel()"
            style="width: 250px"
            [disabled]="resettingPassword"
          >
            Cancel
          </button>
        </form>
      </div>
    </mat-dialog-content>
  </div>
</div>
