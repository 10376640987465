<mat-spinner *ngIf="showSpinner"></mat-spinner>
<div *ngIf="commission !== undefined">
  <div>
    <button mat-flat-button (click)="goBack()">
      <div><mat-icon class="prev">chevron_left</mat-icon>PREV</div>
    </button>
  </div>
  <h2>Commission Details</h2>
  <h3>Order Information</h3>
  <div class="flex-container">
    <mat-list class="flex-item">
      <mat-list-item>
        <span matListItemTitle>Commission Account</span>
        <span matListItemLine>{{
          commission.commissionAccountNumber === ""
            ? "N/A"
            : commission.commissionAccountNumber
        }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>P-Week</span>
        <span matListItemLine>{{ commission.productionWeek }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Account Number</span>
        <span matListItemLine>{{ commission.accountNumber }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Order Number</span>
        <span matListItemLine>{{ commission.orderNumber }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Event ID</span>
        <span matListItemLine>{{ commission.eventId }}</span>
      </mat-list-item>
    </mat-list>
    <mat-list class="flex-item">
      <mat-list-item>
        <span matListItemTitle>Event Description</span>
        <span matListItemLine>{{ commission.eventDescription }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Document ID</span>
        <span matListItemLine>{{ commission.orderId }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>First Name</span>
        <span matListItemLine>{{ commission.customer.firstName }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Last Name</span>
        <span matListItemLine>{{ commission.customer.lastName }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Order Status</span>
        <span matListItemLine>{{ commission.orderStatus }}</span>
      </mat-list-item>
    </mat-list>
  </div>
  <h3>Commission Summary</h3>
  <div class="flex-container">
    <mat-list class="flex-item">
      <mat-list-item>
        <span matListItemTitle>Rental Commission</span>
        <span matListItemLine>${{ commission.rentalCommission }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Purchase Commission</span>
        <span matListItemLine>${{ commission.purchaseCommission }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Total Commission</span>
        <span matListItemLine>${{ commission.totalCommission }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Commission Type</span>
        <span matListItemLine>{{ commission.commissionType }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Commission Status</span>
        <span matListItemLine>{{ commission.commissionStatus }}</span>
      </mat-list-item>
    </mat-list>
    <mat-list class="flex-item">
      <mat-list-item>
        <span matListItemTitle>Approved</span>
        <span matListItemLine>{{ commission.approved }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Approved Date</span>
        <span matListItemLine>{{ commission.approvedDate }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Paid</span>
        <span matListItemLine>{{ commission.paid }}</span>
      </mat-list-item>
      <mat-list-item>
        <span matListItemTitle>Paid Date</span>
        <span matListItemLine>{{ commission.paidDate }}</span>
      </mat-list-item>
    </mat-list>
  </div>
  <h3>Details</h3>
  <table mat-table [dataSource]="commission.details" class="mat-elevation-z8">
    <ng-container matColumnDef="styleType">
      <th mat-header-cell *matHeaderCellDef>Style Type</th>
      <td mat-cell *matCellDef="let detail">{{ detail.styleType ?? "-" }}</td>
    </ng-container>

    <ng-container matColumnDef="legacyStyleCode">
      <th mat-header-cell *matHeaderCellDef>Style Code</th>
      <td mat-cell *matCellDef="let detail">
        {{ detail.legacyStyleCode ?? "-" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="styleGroup">
      <th mat-header-cell *matHeaderCellDef>Rent/Purchase</th>
      <td mat-cell *matCellDef="let detail">{{ detail.styleGroup ?? "-" }}</td>
    </ng-container>

    <ng-container matColumnDef="warehouse">
      <th mat-header-cell *matHeaderCellDef>Warehouse</th>
      <td mat-cell *matCellDef="let detail">
        {{ detail.warehouse ?? "-" }}
      </td></ng-container
    >

    <ng-container matColumnDef="lineStatus">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let detail">{{ detail.lineStatus ?? "-" }}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let detail">
        ${{ detail.commission === 0 ? 0 : detail.price ?? "-" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="commission">
      <th mat-header-cell *matHeaderCellDef>Commission</th>
      <td mat-cell *matCellDef="let detail">${{ detail.commission ?? "-" }}</td>
    </ng-container>

    <ng-container matColumnDef="replacement">
      <th mat-header-cell *matHeaderCellDef>Replacement</th>
      <td mat-cell *matCellDef="let detail">{{ detail.replacement ?? "-" }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
