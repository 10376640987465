import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  convertDateToUnixTimestamp,
  convertUnixTimestampToDate,
} from 'business-logic';
import { Commission } from 'common-types';
import { first } from 'rxjs/operators';
import { CommissionService } from '../../../services/commission/commission.service';

type SearchForm = {
  dateStart: FormControl<Date | null>;
  dateEnd: FormControl<Date | null>;
};

@Component({
  selector: 'jfw-commission-search',
  templateUrl: './commission-search.component.html',
  styleUrls: ['./commission-search.component.scss'],
})
export class CommissionSearchComponent implements OnInit {
  // commissions: Array<Commission> | undefined;
  commissions = new MatTableDataSource<Commission>();
  showSpinner = true;
  filterText = '';
  displayedColumns: string[] = [
    'commissionAccountNumber',
    'productionWeek',
    'eventDescription',
    'customer.firstName',
    'customer.lastName',
    'accountNumber',
    'orderNumber',
    'orderStatus',
    'commissionType',
    'commissionStatus',
    'rentalCommission',
    'purchaseCommission',
    'totalCommission',
    'details',
  ];

  public searchForm = new FormGroup<SearchForm>({
    dateStart: new FormControl<Date | null>(null),
    dateEnd: new FormControl<Date | null>(null),
  });

  constructor(
    private commissionService: CommissionService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.searchLocalStorageRange();
  }

  searchLocalStorageRange(): void {
    let dateStart: number | undefined;
    let dateEnd: number | undefined;
    const localStorageSearchParams =
      this.commissionService.getCommissionsSearchParams();
    if (localStorageSearchParams !== null) {
      const dateParams = JSON.parse(localStorageSearchParams);
      dateStart = JSON.parse(dateParams.startDate);
      dateEnd = JSON.parse(dateParams.endDate);
      if (
        dateStart !== undefined &&
        dateStart !== null &&
        dateEnd !== undefined &&
        dateEnd !== null
      ) {
        this.searchForm.controls.dateStart.setValue(
          convertUnixTimestampToDate(dateStart)
        );

        this.searchForm.controls.dateEnd.setValue(
          convertUnixTimestampToDate(dateEnd)
        );
      }
    }
    const dateStartValue = this.searchForm.controls.dateStart.value;
    if (dateStartValue) {
      dateStart = convertDateToUnixTimestamp(dateStartValue.toString());
    }

    const dateEndValue = this.searchForm.controls.dateEnd.value;
    if (dateEndValue) {
      dateEnd = convertDateToUnixTimestamp(dateEndValue.toString());
    }
    if (dateStart !== undefined && dateEnd !== undefined) {
      this.commissionService.setCommissionsSearchParams(dateStart, dateEnd);
      this.commissionService
        .searchCommissions(dateStart, dateEnd)
        .pipe(first())
        .subscribe({
          next: (commissions) => {
            this.commissions.data = commissions;
          },
        });
    }
  }

  public onSubmit(): void {
    let dateStart: number | undefined;
    let dateEnd: number | undefined;

    const dateStartValue = this.searchForm.controls.dateStart.value;
    if (dateStartValue) {
      dateStart = convertDateToUnixTimestamp(dateStartValue.toString());
    }

    const dateEndValue = this.searchForm.controls.dateEnd.value;
    if (dateEndValue) {
      dateEnd = convertDateToUnixTimestamp(dateEndValue.toString());
    }
    if (dateStart !== undefined && dateEnd !== undefined) {
      this.commissionService.setCommissionsSearchParams(dateStart, dateEnd);
      this.commissionService
        .searchCommissions(dateStart, dateEnd)
        .pipe(first())
        .subscribe({
          next: (commissions) => {
            this.commissions.data = commissions;
          },
        });
    }
  }

  onFilterChange(event: string): void {
    this.filterText = event;
    this.applyFilter();
  }

  private applyFilter(): void {
    this.commissions.filterPredicate = function (
      data,
      filter: string
    ): boolean {
      return (
        data.commissionAccountNumber.toLowerCase().includes(filter) ||
        data.productionWeek.toLowerCase().includes(filter) ||
        data.eventDescription.toLowerCase().includes(filter) ||
        data.customer.firstName.toLowerCase().includes(filter) ||
        data.customer.lastName.toLowerCase().includes(filter) ||
        data.accountNumber.toLowerCase().includes(filter) ||
        data.orderStatus.toLowerCase().includes(filter) ||
        data.commissionType.toLowerCase().includes(filter)
      );
    };
    this.commissions.filter = this.filterText.trim().toLowerCase();
  }

  sortData(sort: Sort) {
    const data = this.commissions?.data.slice();
    if (!sort.active || sort.direction === '') {
      this.commissions.data = data;
      return;
    }
    this.commissions.data = data?.sort((a, b) => {
      const asc = sort.direction === 'asc' ? 0 : -1;
      const desc = sort.direction === 'desc' ? 0 : -1;
      if (sort.active === 'firstName') {
        return a.customer['firstName']! > b.customer['firstName'] ? asc : desc;
      } else if (sort.active === 'lastName') {
        return a.customer['lastName']! > b.customer['lastName'] ? asc : desc;
      } else {
        return a[sort.active as keyof Commission]! >
          b[sort.active as keyof Commission]!
          ? asc
          : desc;
      }
    });
  }

  goBack() {
    this.location.back();
  }
}
