import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalGuard } from '@jfw-library/platform/common-components/portal';
import { DetailsComponent } from './components/account/details/details.component';
import { ManageV2Component } from './components/account/manage-v2/manage-v2.component';
import { ApproveSuccessComponent } from './components/commission/approve/approve-success/approve-success.component';
import { CommissionApproveComponent } from './components/commission/approve/commission-approve.component';
import { CommissionApprovedComponent } from './components/commission/approved/commission-approved.component';
import { CommissionDetailsComponent } from './components/commission/details/commission-details.component';
import { CommissionGroupDetailComponent } from './components/commission/group-detail/commission-group-detail.component';
import { PaidGroupListingComponent } from './components/commission/paid-group-listing/paid-group-listing.component';
import { PaidGroupStatementComponent } from './components/commission/paid-group-statement/paid-group-statement.component';
import { CommissionSearchComponent } from './components/commission/search/commission-search.component';
import { ConfirmationPageComponent } from './components/confirmation/confirmation.component';

const routes: Routes = [
  {
    path: 'account/manage',
    component: ManageV2Component,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'account/details/:accountNumber',
    component: DetailsComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },

  {
    path: 'commission/search',
    component: CommissionSearchComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'commission/approve',
    component: CommissionApproveComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'commission/approved',
    component: CommissionApprovedComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'commission/approve/success',
    component: ApproveSuccessComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'commission/group/detail/:commissionAccountNumber/:fromDate/:toDate',
    component: CommissionGroupDetailComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'commission/details/:orderId',
    component: CommissionDetailsComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'commission/group/paid',
    component: PaidGroupListingComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'commission/confirmation',
    component: ConfirmationPageComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  {
    path: 'commission/group/statement/:id',
    component: PaidGroupStatementComponent,
    canActivate: [PortalGuard],
    data: {
      guardRoles: ['dealerManagement'],
    },
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
