import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CreateAccountFormControls,
  ZodFormUtilities,
} from '@jfw-library/ecommerce/zod-forms';
import { CreateAccountFormSchema } from 'common-types';

@Component({
  selector: 'app-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss'],
})
export class CreateAccountFormComponent {
  @Input() errorMsg = '';
  @Output() formSubmitted = new EventEmitter<
    FormGroup<CreateAccountFormControls>
  >();

  hide = true;
  phone = '';

  // all validation is handled by the zodFormGroupValidator
  form = new FormGroup<CreateAccountFormControls>(
    {
      firstName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      lastName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      newPassword: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      confirmNewPassword: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      validators: [
        ZodFormUtilities.zodFormGroupValidator(CreateAccountFormSchema), // this handles all password validation
      ],
    }
  );

  public get passwordCtrl(): FormControl<string> {
    return this.form.controls.newPassword;
  }

  public addChildForm<key extends keyof CreateAccountFormControls>(
    name: key,
    control: AbstractControl<any, any>
  ) {
    this.form.setControl(name, control as any);
  }

  public setPhone(phone: string | undefined): void {
    if (phone !== undefined) {
      this.phone = phone;
    }
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.formSubmitted.emit(this.form);
    }
  }

  /// returns errors for the field-specific schemas
  getFirstError = ZodFormUtilities.getFirstOfAllFieldErrorsZodFirst;
}
