import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PhoneFormModule } from '@jfw-library/shared/common-components/form-fields';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
} from 'common-types';
import { CreateAccountFormComponent } from './components/create-account-modal/create-account-form/create-account-form.component';
import { CreateAccountModalComponent } from './components/create-account-modal/create-account-modal.component';
import { EmailVerificationModalComponent } from './components/email-verification-modal/email-verification-modal.component';
import { ForgotPasswordModalComponent } from './components/forgot-password-modal/forgot-password-modal.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { SignInModalComponent } from './components/sign-in-modal/sign-in-modal.component';
import { NumbersOnlyDirective } from './directives/numbers-only/numbers-only.directive';
import { AnonTokenGuard } from './guards/anon-token/anon-token.guard';
import { AnonAuthService } from './services/anon-auth/anon-auth.service';
import { AuthV1Service } from './services/auth/authV1/authV1.service';
import { AuthV2Service } from './services/auth/authV2/authV2.service';
import { EnsembleService } from './services/ensemble/ensemble.service';
import { NavigationService } from './services/navigation/navigation.service';
import { ScrollService } from './services/scroll/scroll.service';
import { SearchService } from './services/search/search.service';
import { StyleService } from './services/style/style.service';

@NgModule({
  // Any components that will be imported into a TS file or added via
  // HTML selector (ex. app-phone-form) need to be added to exports array.
  // Sub-components or directives do not need to be exported.
  declarations: [
    CreateAccountFormComponent,
    CreateAccountModalComponent,
    EmailVerificationModalComponent,
    ForgotPasswordModalComponent,
    MaintenanceComponent,
    NumbersOnlyDirective,
    SignInModalComponent,
  ],

  // All modules imported into the Shared Module need to be added
  // to the exports array for the components to function properly.
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    PhoneFormModule,
    ReactiveFormsModule,
  ],
  exports: [
    // components
    CreateAccountFormComponent,
    CreateAccountModalComponent,
    EmailVerificationModalComponent,
    ForgotPasswordModalComponent,
    MaintenanceComponent,
    NumbersOnlyDirective,
    SignInModalComponent,

    // dependencies
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    PhoneFormModule,
    ReactiveFormsModule,
  ],
})
export class EcommerceCoreModule {
  public static forRoot(
    environment: EcommerceMainEnvironment | DealerPortalEnvironment
  ): ModuleWithProviders<EcommerceCoreModule> {
    return {
      ngModule: EcommerceCoreModule,
      providers: [
        AnonAuthService,
        AnonTokenGuard,
        AuthV1Service,
        AuthV2Service,
        EnsembleService,
        NavigationService,
        ScrollService,
        SearchService,
        StyleService,
        {
          provide: 'environment',
          useValue: environment,
        },
      ],
    };
  }
}
