import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { JfwEmailValidator } from '@jfw-library/shared/common-components/form-fields';
import { Site, isValidSite } from 'common-types';
import { firstValueFrom } from 'rxjs';
import { AuthEmailActionsService } from '../../services/auth/auth-email-actions/auth-email-actions.service';
import { AuthV1Service } from '../../services/auth/authV1/authV1.service';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';

export interface ForgotPasswordDialogData {
  site: Site;
}

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss'],
})
export class ForgotPasswordModalComponent {
  rememberMe: boolean = false;
  passwordReset: boolean = false;
  passwordResetError = false;
  resettingPassword: boolean = false;

  signInForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      JfwEmailValidator.isValid(),
      Validators.maxLength(150),
    ]),
  });

  // user: firebase.User | null = null;
  // guestModeEnabled: boolean;
  // invalidCredentials = false;
  // permittedExternalEmails: string[] = environment.permittedExternalEmails;

  constructor(
    // since this is a shared component, and sendResetPassword() requires a site argument,
    // we need to pass in the site value when calling  dialog.open() from the initiating component.
    @Inject(MAT_DIALOG_DATA) public data: ForgotPasswordDialogData,
    public authV1Service: AuthV1Service,
    // private authV2Service: AuthV2Service,
    private authEmailActionService: AuthEmailActionsService,
    // public afAuth: AngularFireAuth,
    // private router: Router,
    private dialog: MatDialog
  ) {
    // this.guestModeEnabled = environment.enableGuestEmulationMode;
  }

  async onPasswordReset() {
    if (!this.data.site) {
      throw new Error('Site must be provided.'); // Developer error
    }

    // Since the site value is being passed in via a value from environment.ts (which itself has an any type when injected in a shared component), we need to validate it here.
    if (!isValidSite(this.data.site)) {
      throw new Error('Site must be valid.'); // Developer error
    }

    if (this.signInForm.valid) {
      this.resettingPassword = true;
      this.signInForm.disable();
      try {
        // const response = await firstValueFrom(timer(3000));
        // throw new Error('Test error');
        const response = await firstValueFrom(
          this.authEmailActionService.sendResetPassword(
            this.signInForm.value.email,
            this.data.site
          )
        );
        // const response = await this.authV2Service.forgotPasswordAsync(
        //   this.signInForm.value.email
        // );

        console.log('response');
        console.log(response);
        this.passwordReset = true;
      } catch (error: any) {
        console.log('WE HAVE AN ERROR!');
        this.passwordResetError = true;
        this.signInForm.enable();
      }
    }
  }

  onCancel(): void {
    this.dialog.closeAll();
    this.dialog.open(SignInModalComponent, {
      autoFocus: false,
      maxWidth: '340px',
    });
  }
}
