import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { first } from 'rxjs';
import { PortalUserService } from '../../services/portal-user/portal-user.service';

enum PortalRoleStatus {
  UNDEFINED = 'undefined',
  ALLOWED = 'allowed',
  DENIED = 'denied',
}
@Injectable({
  providedIn: 'root',
})
export class PortalGuard  {
  portalUser$ = this.portalUserService.getPortalUser$();

  constructor(
    private portalUserService: PortalUserService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const guardRoles: string[] = route.data['guardRoles'] as string[];

      const path = route.routeConfig?.path;

      console.log(
        '%c////////// PORTAL GUARD FOR PATH: ' + path + ' //////////',
        'background: yellow; color: black;font-weight:bold'
      );

      this.portalUser$.pipe(first()).subscribe({
        next: (portalUser) => {
          // console.log('portalUser', portalUser);

          /// Sign-In Page
          if (path === 'sign-in') {
            /// Check if portalUser is defined and has portal roles
            if (
              portalUser &&
              portalUser.portals !== undefined &&
              portalUser.portals.length > 0
            ) {
              console.log(
                '-- Path: Sign-In; Portal User is Defined; Redirect to Home'
              );

              this.router.navigate(['/home']);
              resolve(false);
              return;
            } else {
              console.log('-- Path: Sign-In; Undefined PortalUser; Stay Put');
              // console.log('signed out - remain on sign-in');
              resolve(true);
              return;
            }
          }

          /// All other pages (not Sign-In)
          if (path !== 'sign-in') {
            /// If the portalUser is undefined, route to Sign-In
            if (portalUser === undefined) {
              console.log(
                '-- PortalUser is undefined; Sending back to sign-in'
              );
              this.router.navigate(['/sign-in']);
              resolve(false);
              return;
            }

            if (portalUser) {
              console.log('-- PortalUser is defined. Checking roles....');

              const portalRoleStatus = this.hasPortalRole(
                path,
                portalUser.portals,
                guardRoles
              );

              console.log(
                '%c-- portalRoleStatus: ' + portalRoleStatus,
                'font-weight:bold'
              );

              if (portalRoleStatus === PortalRoleStatus.UNDEFINED) {
                this.portalUserService.signOut();
                resolve(false);
                return;
              }

              if (portalRoleStatus === PortalRoleStatus.DENIED) {
                // console.log('no portal role - navigate to home');
                alert('You do not have access to this page.');
                if (path !== 'home') {
                  console.log(
                    '-- User does not have access. Redirecting to home.'
                  );
                  this.router.navigate(['/home']);
                } else {
                  console.log(
                    '-- User does not have access. User already on home.'
                  );
                }

                resolve(false);
                return;
              }

              if (portalRoleStatus === PortalRoleStatus.ALLOWED) {
                // console.log('has portal role - remain');
                resolve(true);
                return;
              }
            }
          }
        },
      });
    });
  }

  hasPortalRole(
    path: string | undefined,
    roles: string[] | undefined,
    roleRequirements: string[]
  ): PortalRoleStatus {
    // console.log('%c-> hasPortalRole()', 'font-weight:bold');
    console.log('-- Path: ', path);

    console.log("-- User's roles: ", roles);
    console.log('-- Route Guard Requirements: ', roleRequirements);

    let exists = false;

    /// Check if the user has any roles
    if (roles === undefined) {
      return PortalRoleStatus.UNDEFINED;
    }

    if (roleRequirements === undefined || roleRequirements.length === 0) {
      return PortalRoleStatus.ALLOWED;
    }

    if (!roles && roleRequirements.length > 0) {
      return PortalRoleStatus.DENIED;
    }

    // THIS SEEMS REDUNDANT.
    // If there are no claims or roles to compare, then technically
    // they pass this step
    // if (!roles || !roleRequirements || roleRequirements.length < 1) {
    //   return PortalRoleStatus.ALLOWED;
    // }

    for (let r = 0; r < roleRequirements.length; r++) {
      let requiredRole = roleRequirements[r];
      // console.log(requiredRole);

      for (let u = 0; u < roles.length; u++) {
        let userRole: string = roles[u];
        if (userRole === requiredRole) {
          exists = true;
          break;
        }
      }

      if (exists) {
        break;
      }
    }

    if (exists) {
      return PortalRoleStatus.ALLOWED;
    }

    return PortalRoleStatus.DENIED;
  }
}
