<div class="modal-main">
  <div class="close-modal">
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="showSpinner">
    <div style="width: 350px; height: 350px">
      <mat-spinner color="primary" diameter="120"></mat-spinner>
    </div>
  </ng-container>
  <div *ngIf="!showSpinner" class="modal-header-container">
    <!-- <h3 *ngIf="created; else notCreated">Account Created</h3>
    <ng-template #notCreated> -->
    <h3>Create Account</h3>
    <!-- </ng-template> -->
  </div>

  <mat-dialog-content>
    <ng-container *ngIf="!created && !showSpinner">
      <p class="required">* Required</p>

      <app-create-account-form
        [errorMsg]="errorMsg"
        (formSubmitted)="onFormSubmit($event)"
      >
      </app-create-account-form>
      <p>
        <small>
          By creating an account, I agree to the
          <a routerLink="/terms-of-use" target="_blank"
            >Terms and Conditions of Use</a
          >. Read our
          <a routerLink="/privacy" target="_blank">Privacy Policy</a> to learn
          about the collection and use of your personal information.
        </small>
      </p>

      <hr />

      <p>
        <strong>Already have an account?</strong>
      </p>

      <button mat-flat-button color="primary" (click)="onSignIn()">
        Sign-In
      </button>
    </ng-container>

    <!-- <ng-container *ngIf="created && !showSpinner">
      <p style="margin-top: 0px">
        <strong>Congratulations!</strong> You're almost there. We sent you an
        email to verify your account.
      </p>
      <p>After you verify your email, you may sign-in below.</p>
      <button mat-flat-button color="primary" (click)="onSignIn()">
        Sign In
      </button>
    </ng-container> -->
  </mat-dialog-content>
</div>
