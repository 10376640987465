import { ViewportScroller } from '@angular/common'; // import the DOCUMENT token
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(private viewPortScroller: ViewportScroller) { }

  /**
  @param anchor Currently looking for id in html elements
  @param offset Offset page scroll height of page and any stick elements blocking should be offset
  @param delay Some pages wait on async so we delay the scroll
  */
  scrollToAnchor(
    anchor: string,
    offset: [number, number] = [0, 0],
    delay: number = 500
  ): void {
    setTimeout(() => {
      this.viewPortScroller.setOffset(offset);
      this.viewPortScroller.scrollToAnchor(anchor);
    }, delay);
  }

  /**
   *
   * @param delay
   */
  scrollToTop(delay: number = 100): void {
    setTimeout(() => {
      this.viewPortScroller.scrollToPosition([0, 0]);
    }, delay);
  }

  scrollToPosition(
    position: [number, number],
    delay: number = 100
  ): void {
    setTimeout(() => {
      this.viewPortScroller.scrollToPosition(position);
    }, delay);
  }
}
