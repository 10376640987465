import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PlatformUser } from 'business-logic';
import { PortalFeatureModuleGroup } from 'common-types';
import { Subscription, firstValueFrom } from 'rxjs';
import { FeatureModuleService } from './services/feature-module/feature-module.service';
import { PortalUserService } from './services/portal-user/portal-user.service';
// import { PORTAL_ENVIRONMENT } from './portal.module';
import { HttpClient } from '@angular/common/http';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { PORTAL_ENVIRONMENT } from './tokens/portal-environment.token';

@Component({
  selector: 'jfw-base-portal',
  template: '',
  styles: [],
})
export class BasePortalComponent implements OnInit, OnDestroy {
  portalTitle = '';
  portalUser$ = this.portalUserService.getPortalUser$();
  portalUser: PlatformUser | undefined;
  featureModuleGroups: PortalFeatureModuleGroup[] = [];
  subscription = new Subscription();

  environment = inject(PORTAL_ENVIRONMENT);

  constructor(
    private portalUserService: PortalUserService,
    private featureModuleService: FeatureModuleService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    //console.log("BasePortalComponent environment.ts site: ", this.environment.site);

    this.subscription.add(
      this.portalUser$.subscribe({
        next: (portalUser) => (this.portalUser = portalUser),
      })
    );

    this.featureModuleService.featureModuleGroups = this.featureModuleGroups;

    /**
     * This is a test of the App Check service.
     * It can be removed once all of the portals have an api that uses App Check.
     */
    const appCheckTest = firstValueFrom(
      this.httpClient.head('/', { ...requireAppCheck })
    )
      .then((data) => {
        console.log(
          `%c appCheckTest complete: SUCCESS.`,
          'color: white; background-color: #00A36C; '
        );
      })
      .catch((error) => {
        alert('App Check failed.');
        console.error('appCheckTest complete: ERROR', error);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClickSignInButton(): void {
    this.portalUserService.signInWithPopup();
  }

  onClickSignOutButton(): void {
    this.portalUserService.signOut();
  }
}
